.backText {
  color: #8e8e8e;
  font-family: "HealthCVS";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 1px;
  margin-top: 2px;
}
.backIcon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}
