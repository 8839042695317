.backnav{
    position: absolute;
    color: white;
    margin-top: 20px;
    margin-left: 25px;
}
.ccf-d-none{
    display: none;
}
@media (min-width:810px) {
    .backnav{
        margin-top: -60px;
    }
    .navicon{
        font-size: 2.2rem;
    }
}
@media (min-width:1800px) {
    .backnav{
        margin-top: -95px;
    }
}
