/* .footer {
 display: flex;
 height: 55px;
 justify-content: center;
 position: fixed;
 bottom: 0;
 width: 100%;
}*/
.fixedToBottom{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}
.bottomButton {
  width: 50%;
  background-color: var(--red-r-500);
  padding: 1em 5em;
  color: white;
  border-radius: 9999px;
  border: none;
  font-family: "HealthCVS";
  font-weight: 700;
  margin: 10px auto;
  font-size: 15px;
}
.bottomButton:disabled{
  background-color: #E4E4E4;
  color: #434343;
} 
.bottomButton-md {
  background-color: #cc0000;
  padding: 1em 5em;
  color: white;
  border-radius: 9999px;
  border: none;
  font-family: "HealthCVS";
} 
.bottomButton-md-back {
  background-color: #ffffff;
  padding: 1em 5em;
  color: #CC0000;
  border-radius: 9999px;
  border: 1px solid #CC0000;
  font-family: "HealthCVS";
} 