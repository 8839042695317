.cardheader {
  display: flex;
  position: relative;
  background-color: #e9e9e9;
  padding: 25px;
  justify-content: center;
}

.card-btn {
  background-color: white;
  border: 1px solid #cc0000;
  color: #cc0000;
  padding: 5px 13px;
  border-radius: 5px;
  margin-top: -10px;
  font-size: "HealthCVS";
  font-weight: 500;
}

.card-btn:hover {
  cursor: pointer;
}

.med_img {
  width: 100px;
  height: 120px;
}

.status {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
}

.strength {
  position: absolute;
  left: 0;
  top: 0;
  margin: 5px;
  font-size: small;
}

.rec .rec-dot {
  box-shadow: none !important;
  border: 1px solid gray;
  margin-top: -25px;
}

.rec-dot_active {
  background-color: #cc0000 !important;
}

.cardtitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-size: 1rem;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-weight: 500;
  font-family: "HealthCVS";
}

.MuiRating-root.rating {
  color: #FEC30D;
}

/* .MuiRating-icon.MuiRating-iconEmpty{
    display: none!important;
} */
.available {
  color: #61a515;
  font-size: 0.7rem !important;
  margin: 0px 3px;
}

.unavailable {
  color: #cc0000;
  font-size: 0.7rem !important;
  margin: 0px 3px;
}

.reviews {
  padding-left: 10px;
  margin-left: 5px;
  color: #505050;
  font-size: 0.9rem;
}

.productcombo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 200px;
  align-content: center;
}

.pc-img-container {
  display: flex;
  width: 60%;
  height: 60%;
  justify-content: center;
}

.rating-review {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
}

.brand-name {
  color: #505050;
  font-family: "HealthCVSNormal";
  font-size: 0.9rem;
  margin-top: 3px;
  text-transform: uppercase;
  line-height: 2rem;
}

@media only screen and (min-width: 414px) {
  /* .rec .rec-item-wrapper{
    width:auto!important;
 } */
  /* .rec .rec-slider-container{
    width:88%!important;
 } */

}


.itemsReview {
  color: #8C8C8C;
  font-family: 'HealthCVS';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.296px;
  letter-spacing: -0.24px;
}

.rating-new {
  margin-right: 5px;
}

.product-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product_label_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.product_label {
  background: #F0F0F0;
  border-radius: 25px;
  color: #000;
  text-align: center;
  font-family: "HealthCVS";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.29px;
  letter-spacing: -0.24px;
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
}