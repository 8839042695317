.ccf-questionnaire-container{
    background-color: #ffffff;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

  .info-children {
    font-family: "HealthCVSNormal";
    color: #505050;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin: 8px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .info-children-hide{
    display: none;
  }
.ccf-child-option{
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #cccccc;
    padding:25px 0px;
    font-size: 14px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.078);
}
.ccf-child-option-clicked{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 25px 0px;
    border: 1px solid #cccccc;
    margin: 15px 0px;
    font-size: 14px;
    background-color: #cccccc;
    margin-bottom: 20px;
}

.multiselect-grid-container{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    margin-top: 20px;
}

.single-select-item{
    width:100%;
    margin-top: -20px;
}

.multiselect-item{
    width:50%;
    margin-top: -20px;

}
.multiselect-item:last-child{
    width: 100%;

}
.custom-modal>div{
    background-color: #ffffff;
}