.ee_card {
  width: 100%;
}
.landing-image {
  max-width: 100%;
  max-height: 100%;
}
.ee_care_info {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.ee_header {
  font-family: HealthCVSNormal;
  font-size: 1.8rem;
  font-weight: 700;
  margin: 10px 0px;
  padding: 2px 10px;
}
.ee_button {
  width: 95%;
  background-color: #cc0000;
  padding: 1.1em 1em;
  color: #ffff;
  font-family: inherit;
  border: none;
  border-radius: 13px;
  cursor: pointer;
}

.ee_sub_info {
  font-family: HealthCVSNormal;
  font-size: 0.9rem;
  margin: 15px 0px;
  padding: 5px 10px;
  font-weight: 700;
}
.ee_disclamier {
  font-family: HealthCVSNormal;
  font-size: 0.8rem;
  margin: 15px 0px;
  padding: 5px 10px;
  font-weight: 400;
}
.ee_question_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  @media (max-width: 340px) {
    grid-template-columns: 1fr;
  }
}
.ee_question {
  font-family: "HealthCVS";
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.078);
  margin-bottom: 10px;
  padding: 0 18px;
  font-size: 16px;
  cursor: pointer;
  height: 70px;
  font-weight: 700;
  /* new styles */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
}
.ee_question,
.ee_question_grid > span {
  color: #555;
}
.ee_question_grid > span {
  margin-top: 8px;
  text-align: center;
}

.ee_question_active {
  font-family: "HealthCVS";;
  border: 1px solid #c00;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.078);
  margin-bottom: 10px;
  padding: 25px;
  font-size: 14px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ee_question_grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "HealthCVS";
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.078);
  padding: 6px;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  justify-content: left;
  border-radius: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ee_question_grid_active {
  display: flex;
  align-items: center;
  font-family: "HealthCVS";
  border: 1px solid #c00;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.078);
  padding: 6px;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  justify-content: left;
  border-radius: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ee_questioncard_container {
  font-family: "HealthCVS";
  font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.32px;
}
.ee_question_title {
  font-size: 1.5rem;
  color: #0b315e;
  font-family: "HealthCVS";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.ee_question_subtitle {
  font-size: 1rem;
  color: var(--neutral-n-500);
}
.loading {
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  position: absolute;
  z-index: 500;
  top:0;
  left:0
}
/* @media only screen and (min-width: 600px) {
    .ee_card{
        width: 50%;
    }
    .ee_question{
        width: 50%;
    }
  } */

/* @media only screen and (min-width: 850px) {
    .ee_card{
        width: 30%;
    }
    .ee_question{
        width: 30%;
    }
    .ee_question_container,.ee_questioncard_container{
        width: 30%;
    }
  } */
