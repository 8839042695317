.appointment{
    color: #cc0000;
    font-size: 1rem;
    margin-bottom: 20px;
}
.childcarehero{
    position: relative;
}
.simpleshopicon{
  position: absolute;
  right:0;
  margin-top: 25px;
  width: 32%;
  height: auto;
}

.hero{
    width: 100%;
}