.re_disclaimer_container{
  
    background-image: url('../../../assets/disclaimer.jpg');
    background-size: cover;
    height: 100vh;
    width: 100%;
}
.desc_layer{
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255,0.8);
    align-items: center;
    width: 100%;
    height: 100vh;
}
.disclaimer_content{
    margin: 13px;
}
.disclaimer_title{
    font-family: HealthCVS;
    font-size: 20px;
    color:#CC0000;
}
.disclaimer_desc{
    font-family: HealthCVSNormal;
    font-size: 0.8rem;
    line-height: 1.4rem;

}
.btn-primary{
    background-color: #CC0000;
    border-radius: 16px;
    border:none;
    padding: 1em 2em;
    color: #ffffff;
    margin-right: 5px ;
    font-family: "HealthCVS";
}
.btn-primary:hover{
    cursor: pointer;
}
.btn-secondary{
    background-color: #ffffff;
    border-radius: 16px;
    border:0.5px solid #CC0000;
    padding: 1em 2.1em;
    color: #CC0000;
    margin-right: 5px ;
    font-family: "HealthCVS";
}