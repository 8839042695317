/* .landing{
    position: relative;
    height: 100Vh;
}
.bgimage{
    position: fixed;
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh;
    object-fit: cover;
}
  .board{
    background: rgba(255, 255,255,0.7)!important;
    position: fixed;
    max-width: 80vw ;
    height: auto;
    bottom:9vh;
    padding: 10px;
    padding-left: 20px;
}
.title{
    font-family: "HealthCVS";
    color: #CC0000;
    font-size: 2.1rem;
    margin-bottom: 20px;
}
.intro-text{
    font-family: "HealthCVSNormal";
    margin-bottom: 20px;
}
.btn{
    display: block;
    font-weight: bold;
    background-color: #CC0000;
    width: 50vw;
    padding: 15px 25px;
    color:  white;
    border:none;
    border-radius: 13px;
    margin-bottom: 20px;
} */
.cvs-home-container {
  width: 100%;
  height: 94vh;
}

.cvs-home-image {
  width: 100%;
  height: 40%;
  background-image: url("../../assets/homepage_mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.cvs-hompage-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 50%;
}

.cvs-hompage-title-info {
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.homepage-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.hompage-grid-item {
  text-align: center;
  border: 1px solid #cccc;
  padding: 2em 2em;
  font-size: 13px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}

.comingsoon {
  font-size: 12px;
}

.heading {
  color: var(--secondary);
  font-family: "HealthCVS";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.52px;
}

.subtitle {
  color: var(--neutral-n-500);
  font-family: "HealthCVS";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.32px;
  margin: 8px 0;
}

.disclaimerText {
  color: var(--neutral-n-400);
  font-family: "HealthCVS";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  margin: 20px 0 0 0;
}

.pharmacist_img {
  width: 100%;
  object-fit: contain;
  @media (min-width: 480px) {
    width: 50%;
  }

  @media (min-width: 1024px) {
    width: 35%;
  }
}
.button_red {
  width: 100%;
  background: var(--red-r-500);
  color: white;
  border: none;
  font-family: "HealthCVS";
  font-weight: 700;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  cursor: pointer;
  font-size: 15px;
  @media (min-width: 780px) {
    width: 80%;
  }

  @media (min-width: 1024px) {
    width: 60%;
  }
}

/* eye component */
.categoryItemContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  /* margin-right: -18px; */
  justify-content: space-between
}

.categoryItems {
  padding: 10px 5px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  border-radius: 16px;
  border: 1.5px solid var(--neutral-gray-6, #bfbfbf);
  margin-bottom: 18px;
  cursor: pointer;
  width:95%;
 
}

.categoryItems > p {
  color: var(--neutral-n-600);
  text-align: center;
  font-family: "HealthCVS";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.selectCategoryTitle {
  color: #0b315e;
  font-family: "HealthCVS";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Banner container */
.bannerContainer {}

.selectText {
  color: #717171;
  font-family: "HealthCVS";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.selectCategory{
  color: var(--neutral-n-600);
  font-family: 'HealthCVS';
  font-size:  20px;
  font-weight: 500;
  margin-top: 15px;
  padding-left: 2px;
  font-style: normal;
  line-height: normal;
}


.text {
  color: var(--neutral-n-600);
  text-align: center;
  font-family: "HealthCVS";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}