.productinfo{
    font-family: "HealthCVSNormal";
    color:#505050;
    margin-bottom: 0px;
}

.skuStyle {
    font-family: "HealthCVSNormal";
    color:#505050;
    font-size: 0.9rem;
    margin-top: -5px;
    margin-bottom: -1px;
}
.productDesc{
    font-family: "HealthCVSNormal";
    font-size: 1.4rem;
    margin-top: 4px;
}
.carousel{
    border: 1px solid #E9E9E9;
    padding:10px;
    margin-bottom: 20px;
    margin-top: -8px;
}
.prodavailable{
    font-family: "HealthCVSNormal";
    color: #61A515;
    font-size: 0.9rem;
}
.produnavailable{
    font-family: "HealthCVSNormal";
    color: #cc0000;
    font-size: 1rem;
}
.buttongroup{
    position: relative;
    display: flex;
    /* background-color: aqua; */
    height: 50px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px -3px 6px rgba(0, 0, 0, 0.12);
}
.MuiSvgIcon-root.selectIcon{
    position: absolute!important;
    top:0!important;
    margin-left:40%!important;
    margin-top: 15px!important;
    pointer-events: none;
    color: #505050!important;

}

.buttongroup>select{
    width: 100%;
    color:#505050;
    padding: 5px;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background-color: white;
    border:none;
    padding: 5px 10px
}
.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters.custAccordion{
    height: 20px!important;
    font-family: "HealthCVSNormal";
    padding: 0px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.accordionWrapper::before{
    background-color: white!important;
}


.buttongroup>button{
    width: 100%;
    border:none;
    background-color: #cc0000;
    color: white;
    font-family: "HealthCVS";
}
.arriavalinfo{
    font-family: "HealthCVSNormal";
    font-size: 0.8rem;
    color: #505050;
}
.prodprice{
    font-family: "HealthCVSNormal";
    font-size: 1.2rem;
}
hr{
    border: 1px solid #E9E9E9;
}
.rec.rec-arrow.rec.rec-arrow-left,.rec.rec-arrow.rec.rec-arrow-right{
    border-radius: none!important;
    background-color: white!important;
    box-shadow: none!important;
    color: #505050!important;
    font-size: 20px!important;
}
.rec.rec-arrow.rec.rec-arrow-left:disabled,.rec.rec-arrow.rec.rec-arrow-right:disabled{
    color:#E9E9E9!important;
}
.prodreviews{
    font-family: "HealthCVSNormal";
    color: #505050;
    margin-left: 10%;
    margin-top: 0px;
}
.rec.rec-pagination{
    margin-left: -20px!important;
}

.custDetails{
    font-family: "HealthCVSNormal";
}