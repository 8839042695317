/* CSS Reset */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}
body {
  background-color: #ffffff;
}
/* Typogrpahy*/
.ccf-heading {
  font-size: 2.25rem;
  font-family: "HealthCVS";
}
.ccf-question-heading {
  font-size: 1.5rem;
  font-family: "HealthCVS";
}
.question-heading {
  color: #0b315e;
  font-family: "HealthCVS";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ccf-info-text {
  font-size: 0.8rem;
  font-family: "HealthCVS";
  font-weight: 400;
}
.cvs-homepage-title {
  font-size: 1rem;
  font-family: "HealthCVS";
}

.cvs-network-error-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #c00;
  border-color: #c00;
}
.cvs-network-error-btn:hover {
  background-color: #c00;
  color: white;
}
/* margin */
.mt-1 {
  margin-top: 10px;
}

/*utility class*/
.text-primary {
  color: #cc0000;
}
.text-secondary {
  color: #000000;
}
.text-info {
  color: #505050;
}
.text-info {
  color: #505050;
}
.text-white {
  color: #ffffff;
}
/*Buttons*/
.ccf-btn-primary {
  color: #ffffff;
  background-color: var(--red-r-500);
  font-family: "HealthCVS";
  font-size: 0.9rem;
  padding: 1em 3.5em;
  border: none;
  border-radius: 16px;
}
.ccf-btn-primary:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  body {
    background-color: #ffffff;
  }
}
.ccf-container {
  max-width: 1366px;
  margin: auto;
  background-color: #ffffff;
  border: 0.5px solid #e6e6e6;
  border-bottom: none;
  height:100dvh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.default-btn {
  padding: 1em 2em;
  border: none;
  font-family: "HealthCVS";
  margin: 10px 20px;
  font-weight: 400;
  border-radius: 500px;
}
.btn-primary-new {
  color: white;
  background-color: #F00;
  font-size: 15px;
  
}

.btn-primary-new:disabled{
  background-color: #E4E4E4;
  color: #434343;
}

.btn-secondary-new {
  background-color: #ffffff;
  border: 1px solid var(--red-r-500);
  color: var(--red-r-500);
  font-size: 15px;
}
.text-grey {
  color: #717171;
}
.text-grey-dark {
  color: #262626
}
.text-grey-dark2 {
  color: #8c8c8c
}
:root {
  --swiper-theme-color: #cc0000 !important;
}
html{
font-size: 16px;
}