.wrapper {
  position: relative;
  display: block;
}
.radioBtn {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  padding: 15px;
}
.checkBtn {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  padding: 20px;
}
.checkvalue {
  display: block;
  padding: 20px 30px;
}
.value {
  display: flex;
  padding: 10px 8px 8px 10px;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.078);
  margin: 15px 5px;
  height: 80px;
  /* width: 85%; */
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 18px;
  font-family: "HealthCVS";
  justify-content: space-evenly;
}

.coldNFluLabel {
  font-size: 14px;
  color: #cc0000;
  line-height: 1.5rem;
}
.coldNFluLabel-subText{
  font-family: "HealthCVS";
  font-size: 10px;
  color: #000000;
}

.singleSelect {
  display: flex;
  padding: 0 15px;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.078);
  margin: 15px 0px;
  height: 70px;
  font-family: "HealthCVS";
  font-size: 14px;
  line-height: 18px;
  justify-content: space-between;
}
.singleSelectText{
  color: var(--neutral-n-600);
  font-family: "HealthCVS";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  width: 83%;
}
.ccf-question-label{
  line-height: 1.2rem;
}

.optionQuantity{
  margin-top: 5px;
  color: #cc0000;
  font-size: 12px;
}
.radioBtn:checked + .value {
  border: 1.4px solid #cc0000;
  box-shadow: 0px 2px 2px 1px rgba(206, 56, 56, 0.2);
}

.radioBtn:checked + .singleSelect {
  border: 1.4px solid #cc0000;
  box-shadow: 0px 2px 2px 1px rgba(206, 56, 56, 0.2);
}

.question {
  font-family: "HealthCVS";
  font-size: 24px;
  line-height: 2rem;
  margin-top: 20px;

}
.info {
  font-family: "HealthCVSNormal";
  color: #505050;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-top: 15px;
}

.gridList {
  position: relative;
}

.symptomIcon {
  height: 50%;
  width: auto;
  /* margin-top: 10px; */
  align-self:center;
  /* border: 1px solid #cc0000; */
  /* margin-right: 2px; */
}
/**/
.main-divider-container{
  display: flex;
  margin-top: 20px;
}
.line-container{
  width: 100%;
  display: flex;
  align-items: center;
}
.line{
  width: 100%;
  height: 2px;
  background:  #E9E9E9;
}
.main-divider-container{
  display: flex;
}
@media(min-width: 200px){
  .coldNFluLabel-subText{
    font-size: 0px;
   }
  
}
@media(min-width: 320px){

  .value>label{
  font-family: "HealthCVS";
  font-size: 0.75rem;
  }
  .symptomIcon {
    height: 45%;
    width: auto;
    align-self:center;   
  }
}


@media(min-width: 360px){

  .value>label{
    font-family: "HealthCVS";
    font-size: 0.8rem;
  }
  .symptomIcon {
    height: 50%;
    width: auto;
    align-self:center;   
  }
  .coldNFluLabel{
    font-size: 14px;
  }
  .coldNFluLabel-subText{
    font-size: 9px;
   }
}

@media(min-width: 375px){

  .value>label{
    font-family: "HealthCVS";
    font-size: 0.8rem;
  }
  .symptomIcon {
    height: 50%;
    margin-right: 2px;
  }
}
@media(min-width: 400px){

  .value>label{
    font-family: "HealthCVS";
    font-size: "12px";
  }
  .symptomIcon {
    height: 60%;
    margin-right: 2px;
  }
  .coldNFluLabel{
    font-size: 14px;
  }
 .coldNFluLabel-subText{
  font-size: 10px;
 }
}

@media(min-width: 900px){

  .value>label{
    font-family: "HealthCVS";
    font-size: "12px";
  }
  .symptomIcon {
    height: 70%;
    /* margin-right: 2px; */
  }
  .coldNFluLabel{
    font-size: 14px;
  }
 .coldNFluLabel-subText{
  font-size: 12px;
 }
}






.item_active{
  border: 1.4px solid #cc0000;
  box-shadow: 0px 2px 2px 1px rgba(206, 56, 56, 0.2);
}