@font-face {
  font-family: "HealthCVS";
  src: url('./assets/App/CVSHealthSans_A_Bd.ttf');
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: "HealthCVS";
  src: url('./assets/App/CVSHealthSans_A_Md.ttf');
  font-display: swap;
  font-weight: 500;
}
 @font-face {
  font-family: "HealthCVS";
  src: url('./assets/App/CVSHealthSans_A_Rg.ttf');
  font-display: swap;
  font-weight: 400;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "HealthCVS";
}

:root {
  --secondary: #002744;
  --neutral-n-500: #717171;
  --red-r-500: #E00;
  --neutral-n-400: #8E8E8E;
  --neutral-n-600: #555
}