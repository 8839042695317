/* .MediaScroller{
    margin-top: 15px;
    display: grid;
    gap:1rem;
    grid-auto-flow: column;
    grid-auto-columns: 70vw;
    overflow-x:auto ;
    overscroll-behavior-inline: contain;
} */
.snapsinline {
  scroll-snap-type: inline mandatory;
}
.snapsinline > * {
  scroll-snap-align: start;
}
.media {
  margin: 15px;
  padding: 10px;
}
.appointment {
  border: none;
  margin-left: -5px;
  background-color: white;
  text-decoration: underline;
  color: #cc0000;
}
.MuiDialogContent-root.MuiDialogContent-dividers {
  padding: 0px 16px 16px 16px !important;
}

.carouselStyle {
  border: none;
  margin-top: -30px;
  margin-left: -20px;
}

.startOver {
  width: 50%;
  background-color: #cc0000;
  padding: 8px 15px;
  color: white;
  border-radius: 5px;
  height: 80%;
  text-align: center;
  border: none;
  margin-left: 25%;
}
.product-preference-question {
  font-family: "HealthCVS";
  font-size: 24px;
  line-height: 2rem;
  margin-bottom: 15px;
}
.product-preference-start-over {
  background-color: var(--red-r-500);;
  border: none;
  color: white;
  padding: 1em 5em;
  border-radius: 16px;
  font-family: "HealthCVS";
}
.start-over-container {
  padding: 16px;
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: -12px;
  left: 0;
  right: 0;
}
.btn-primary-prefence {
  background-color: var(--red-r-500);
  border-radius: 9999px;
  border: none;
  padding: 1em 3em;
  color: #ffffff;
  margin-right: 5px;
  font-family: "HealthCVS";
  font-size: 12px;
}
.btn-primary-prefence:hover {
  cursor: pointer;
}
.btn-secondary-preference {
  background-color: #ffffff;
  border-radius: 9999px;
  border: 1px solid var(--red-r-500);
  padding: 1em 1em;
  color: var(--red-r-500);
  margin-right: 5px;
  font-family: "HealthCVS";
  font-size: 12px;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-color: white;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 20px 0px;
}

.swiper-pagination {
  margin-bottom: -15px;
  padding: 5px;
}

.productSlider_heading {
  color: #0b315e;
  font-family: "HealthCVS";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin:20px 0
}
