.ccf-header {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  padding: 24px 24px 0 24px;
}
.ccf-header-back-arrow {
  color: white;
  margin-top: -5px;
}
.cvs-logo {
  width: 120px;
  object-fit: contain;
  pointer-events: all !important;
}
.ccf-back-icon {
  color: #ffffff;
  font-size: 1.1rem !important;
}
.ccf-header-logo-container {
  margin-bottom: 14px;
}
