.disclaimerpage{
    margin-top: -20px;
   background-image: url('../../../assets/disclaimer.jpg');
    height: 100vh;
    background-size: cover;
}
.disclaimertxt{
    font-family: "HealthCVSNormal";
}
.layer{
    background-color: rgba(255, 255, 255,0.8);
    width: 100vw;
    height: 100vh;
}

.disclaimer{
    font-size: 1.4rem;
    color:#cc0000;
    padding-top: 100px;
    line-height: 1.4rem;
}
.learn-more{
    font-family: 'HealthCVSNormal';
    color:#CC0000;
    font-size:1rem
}
