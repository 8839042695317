.gridContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        'img  heading heading  '
        'img brand brand '
        'img rating review '
    ;
    gap:8px;
    align-items: center;
    border:1px solid #E9E9E9;
    border-radius: 4px;
    /* padding: 10px; */
    margin-bottom: 10px;
    width:350px;
}
.grid-item-0{
    height: 100%;
    grid-area: img;
}
.grid-item-1{
    font-size: 0.9rem;
    grid-area: heading;
}
.grid-item-2{
    font-family: "HealthCVSNormal";
    font-size: 0.8rem;
    grid-area: brand;
    color:rgba(0, 0, 0, 0.64);
}
.grid-item-3{
    grid-area: rating;
}
.grid-item-4{
    font-family: "HealthCVSNormal";
    font-size: 0.65rem;
    grid-area: review;
    color: #505050;
}
